import React, { useState } from 'react';
import { Link } from 'gatsby';

export default class ListLink extends React.Component {
  render(){
      return (
        <div className='list-link'>
        <Link to={this.props.to}>
          <div className='list-link-value'>{this.props.children}</div>
        </Link>
      </div>
    )
  }
}