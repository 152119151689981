import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Instagram from '../images/instagram.svg'

const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="sub-footer">
            <ul>
              <li>
                <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-show-count="false"></a>
              </li>
              <li>
                <iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Ficanspellmycolors.com&layout=button&size=small&width=67&height=20&appId" width="67" height="20" style={{border:"none", overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
              </li>
              <li style={{background: 'white', borderRadius: '4px', padding: '2px 6px', height: '20px'}}><a href="https://www.instagram.com/icanspellmycolors321/" target="_blank" style={{display:'flex', fontFamily: 'sans-serif', fontSize: '11px', width: '58px'}}><img style={{height: '16px', marginRight: '5px'}} src={Instagram} /> Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            contact {
              email
              phone
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} />}
  />
);
